<template>
  <v-container fluid>
    <div class="fill-height pb-2">
      <h1 class="text-h4">{{ $lang.header.moduleDetails }}</h1>
      <v-divider class="my-2"></v-divider>
      <v-row wrap no-gutters>
        <v-col
          v-if="!userCanEdit && !initialLoading"
          cols="12"
          sm="12"
          md="8"
        >
          <v-alert dense color="warning" style="color: black">{{ $lang.errors.readOnly }}</v-alert>
        </v-col>
        <v-col
          cols="12"
          style="max-width: 1440px"
        >
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider color="accent"></v-tabs-slider>

            <v-tab
              :key="0"
              data-cy="tab-details"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              :key="1"
              data-cy="tab-up-download"
            >
              {{ $lang.labels.upDownload }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-permissions"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
            <v-tab
              :key="3"
              data-cy="tab-history"
            >
              {{ $lang.labels.history }}
            </v-tab>
            <v-tab
              v-if="validationShow"
              :key="4"
              data-cy="tab-validation"
            >
              {{ $lang.labels.validation }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="fill-height">
            <v-tab-item
              :key="0"
            >
              <v-card class="pa-2 fill-height">
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <v-row wrap no-gutters justify="space-between" class="pt-1 pb-3">
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="module.name"
                        outlined
                        dense
                        :label="$lang.labels.name"
                        required
                        :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit"
                        data-cy="module-name"
                        class="pb-1 required-asterisk"
                        @input="isDirty = true"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <v-btn
                        :disabled="!isEdit || !isTriggerRest || isDirty"
                        color="primary"
                        data-cy="module-swagger-button"
                        @click="fetchSwagger()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi-code-tags-check
                        </v-icon>
                        {{ $lang.actions.downloadSwagger }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="!initialLoading" wrap no-gutters>
                    <v-col cols="12" class="pb-2">
                      <v-expansion-panels v-model="panelIndex" color="surface">
                        <template v-for="(item, i) in resourcesTypes">
                          <v-expansion-panel
                            :key="i"
                          >
                            <v-expansion-panel-header>
                              <v-icon class="module-icon">{{ getModuleIcon(item) }}</v-icon>
                              {{ item === 'TEXT_TEMPLATE' ? $lang.status.TEMPLATE : $lang.status[item] }}
                              <span class="color-primary">&nbsp;({{ resourceTypeLength(item) }})</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content v-if="i === panelIndex">
                              <values-component
                                :can-edit="userCanEdit"
                                :is-edit="isEdit"
                                :type="item"
                                :all-resources="module.resources"
                                :default-values="module.resources.filter((x) => x.type === item)"
                                @setValues="setResources(item, $event)"
                                @setAdditionalValues="setAdditionalResources($event)"
                                @makeDirty="isDirty = $event"
                              ></values-component>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" class="pt-1">
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          class="button-default-width"
                          color="secondary"
                          data-cy="module-back"
                          :to="{ name: 'local', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            right
                            dark
                            class="ma-0"
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="module.name"
                          :title="$lang.actions.delete"
                          type="local"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'local-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          class="ml-2"
                          :delete-success="deleteSuccess"
                          @submit="deleteModuleFunct($event)"
                          @closeDialog="''"
                          @closeAfterDelete="$router.push({ name: 'local' })"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit || !isEdit"
                        color="accent"
                        class="button-default-width ml-2 color-accent-text"
                        data-cy="module-export"
                        @click="exportModuleFunction()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-package-up
                        </v-icon>
                        {{ $lang.actions.export }}
                      </v-btn>
                      <v-btn
                        :disabled="!valid || lock || !userCanEdit || module.resources.length === 0"
                        color="success"
                        class="button-default-width ml-2"
                        data-cy="module-submit"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap class="pt-4">
                    <div class="d-flex align-items-center align-center">
                      <h3>{{ $lang.labels.descriptions }}</h3>
                      <v-btn
                        color="accent"
                        class="ml-2"
                        icon
                        :disabled="!userCanEdit"
                        data-cy="module-description-add"
                        @click="module.descriptions.push({ language: '', description: '' })"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <v-col
                      cols="12"
                    >
                      <template v-for="(desc, i) in module.descriptions">
                        <v-row :key="i" no-gutters wrap class="pt-2">
                          <v-col
                            cols="3"
                            class="pr-1"
                          >
                            <v-select
                              v-model="desc.language"
                              :items="availableLanguages(desc)"
                              :label="$lang.labels.language"
                              outlined
                              dense
                              required
                              :rules="[v => !!v || $lang.labels.required]"
                              :readonly="!userCanEdit"
                              :data-cy="`module-description-language-${i}`"
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="7"
                          >
                            <v-text-field
                              v-model="desc.description"
                              outlined
                              dense
                              :label="$lang.labels.description"
                              required
                              :rules="[v => !!v || $lang.labels.required]"
                              :readonly="!userCanEdit"
                              :data-cy="`module-description-${i}`"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="2"
                            class="pl-1 text-right"
                          >
                            <v-btn
                              color="error"
                              class="ml-2"
                              icon
                              :data-cy="`module-description-delete-${i}`"
                              @click="confirmDeletion(desc, i)"
                            >
                              <v-icon
                                size="25"
                              >mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="1"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters justify="space-between" class="py-1">
                  <v-col cols="12">
                    <div class="d-inline-flex" style="width: 100%">
                      <v-autocomplete
                        v-model="module.loadCredentialId"
                        outlined
                        dense
                        :items="credentials"
                        :loading="isLoadingCredentials"
                        :search-input.sync="searchCredentials"
                        clearable
                        hide-no-data
                        item-text="name"
                        item-value="id"
                        :label="$lang.labels.credential"
                        :placeholder="$lang.actions.startTyping"
                        prepend-inner-icon="mdi-cloud-search-outline"
                        :readonly="!userCanEdit"
                        data-cy="module-loadCredentialId"
                        @change="module.loadCredentialId ? '' : module.loadDirection = ''; isDirtyUpDown = true; info = $lang.hints.settingsChangedStoreToUpDownload"
                      ></v-autocomplete>
                      <v-btn
                        icon
                        light
                        color="primary"
                        class="ml-1"
                        data-cy="module-search-credential"
                        @click="searchCredentialsFunction(searchCredentials)"
                      >
                        <v-icon
                          dense
                          small
                        >
                          mdi-refresh
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pl-0"
                  >
                    <v-select
                      v-model="module.loadDirection"
                      :items="availableDirections"
                      :label="$lang.labels.loadDirection"
                      outlined
                      dense
                      required
                      :disabled="!module.loadCredentialId"
                      :rules="[module.loadCredentialId ? v => !!v || $lang.labels.required : true]"
                      :readonly="!userCanEdit"
                      data-cy="module-loadDirection"
                      @change="isDirtyUpDown = true; info = $lang.hints.settingsChangedStoreToUpDownload"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="3"
                    class="pl-0"
                  >
                    <v-text-field
                      v-model="module.remoteId"
                      outlined
                      dense
                      :label="$lang.labels.remoteId"
                      readonly
                      data-cy="module-remoteId"
                      style="padding-right: 44px"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    class=""
                  >
                    <v-btn
                      :disabled="!isEdit || module.loadDirection === '' || isDirtyUpDown"
                      color="primary"
                      class="button-default-width mr-1"
                      data-cy="module-loadDirection-button"
                      @click="handleTransferModule()"
                    >
                      {{ module.loadDirection === 'UPLOAD' ? $lang.actions.uploadModule : $lang.actions.downloadModule }}
                    </v-btn>
                    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="3"
              class="fill-height"
            >
              <v-card class="py-1 fill-height">
                <div style="width: 100%; height: 100%">
                  <v-row dense no-gutters>
                    <v-col cols="12">
                      <Table
                        :items="history"
                        @fetchHistory="fetchHistory($event)"
                        @restoreHistory="restoreModule($event)"
                      ></Table>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="4"
              class="fill-height"
            >
              <v-card flat class="pt-1 fill-height">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-4">
                    <v-expansion-panels flat color="surface">
                      <template v-for="(item, i) in validationResult">
                        <v-expansion-panel
                          :key="i"
                        >
                          <v-expansion-panel-header>
                            {{ $lang.status[item.type] }}: <span :class="(item.missedRequiredResources && item.missedRequiredResources.length > 0) || !item.hasPermission || !item.isExist ? 'color-error' : 'color-primary'">&nbsp;{{ item.name }}</span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row wrap no-gutters class="pb-2">
                              <v-col cols="6">
                                <h4>&nbsp;{{ $lang.labels.permission }}: <span :class="item.hasPermission ? 'color-primary' : 'color-error'">&nbsp;{{ item.hasPermission ? $lang.labels.yes : $lang.labels.no }}</span></h4>
                              </v-col>
                              <v-col cols="6">
                                <h4>&nbsp;{{ $lang.labels.exist }}: <span class="color-primary">&nbsp;{{ item.isExist ? $lang.labels.yes : $lang.labels.no }}</span></h4>
                              </v-col>
                            </v-row>
                            <v-row v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0" no-gutters class="pb-2">
                              <v-divider></v-divider>
                            </v-row>
                            <div v-if="item.missedRequiredResources && item.missedRequiredResources.length > 0" class="pb-2" style="width: 100%">
                              <h3 class="color-error pb-2">&nbsp;{{ $lang.labels.missingParams }}</h3>
                              <template v-for="(missing, index) in item.missedRequiredResources">
                                <v-row :key="index" no-gutters class="pb-2">
                                  <v-col cols="12">
                                    <h4>&nbsp;{{ $lang.status[missing.type] }}: <span class="color-primary">&nbsp;{{ missing.name }}</span></h4>
                                  </v-col>
                                </v-row>
                              </template>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </template>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="30%">
        <yes-no-modal :title="$lang.actions.delete" :action-text="$lang.header.sureDeleteDesc" @submit="deleteDesc" @closeDialog="tempItem = null; tempIndex = null; yesNoShow = false"></yes-no-modal>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import ValuesComponent from './Values'
import Table from '@/components/ui/datatables/FlowyHistoryDatatable'
import {
  getModuleByIdUsingGET as getModule,
  updateModuleUsingPUT as updateModule,
  createModuleUsingPOST as createModule,
  getHistoriesUsingGET as getHistories,
  exportUsingGET as exportModule,
  getProcessCredentialsUsingGET as getCredentials,
  getProcessCredentialByIdUsingGET as getCredential,
  hubUploadModuleUsingGET as uploadModule,
  hubModuleUpdateUsingGET as downloadModule,
  swaggerUsingGET as getSwagger,
  deleteModuleUsingDELETE as deleteModule
} from '@/utils/api'
import YesNoModal from '@/components/ui/modals/YesNoModal'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import { mapActions } from 'vuex'
import { getRolesWithoutAuth } from '@/utils/helpers'

export default {
  components: {
    ActionButtonWithConfirmation,
    ValuesComponent,
    Table,
    YesNoModal,
    UserRolesSelect
  },
  data() {
    return {
      isDirty: false,
      isDirtyUpDown: false,
      err: '',
      success: '',
      info: '',
      tab: 0,
      valid: false,
      isEdit: false,
      initialLoading: true,
      loading: true,
      lock: false,
      module: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        resources: [],
        roles: [],
        descriptions: [],
        loadCredentialId: '',
        loadDirection: '',
        remoteId: ''
      },
      resourcesTypes: [
        'TRIGGER_CRON',
        'TRIGGER_EVENT_HANDLER',
        'TRIGGER_MESSAGING',
        'TRIGGER_REST',
        'PROCESS',
        'PROCESS_CREDENTIAL',
        'TEXT_TEMPLATE',
        'GLOBAL_TRANSLATION',
        'VALIDATION_RULE',
        'PLUGIN',
        'LIBRARY',
        'ENTITY',
        'MODULE',
        'SETTING'
      ],
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      history: { items: [], meta: {} },
      isSuperUser: false,
      languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
      directions: ['UPLOAD', 'DOWNLOAD'],
      tempIndex: null,
      tempItem: null,
      yesNoShow: false,
      credentials: [],
      isLoadingCredentials: false,
      searchCredentials: '',
      validationShow: false,
      validationResult: null,
      openedExpansion: null,
      panelIndex: null,
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false
    }
  },
  computed: {
    isTriggerRest () {
      const presentTypes = this.module.resources.map((x) => x.type)

      return presentTypes.includes('TRIGGER_REST')
    },
    availableDirections() {
      return this.directions.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    },
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.module.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.module.modifiedOn)}, ID: ${this.module.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    }
  },
  watch: {
    info(newValue) {
      if (newValue) {
        this.addSnackbar({
          message: newValue,
          timeout: 5000,
          color: 'secondary'
        })
        setTimeout(() => this.info = '', 5000)
      }
    },
    searchCredentials: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchCredentialsFunction(val)
        }
      }
    },
    tab() {
      if (this.tab === 3) {
        this.fetchHistory()
      }
    }
  },
  created() {
    this.isEdit = this.$route.name === 'moduleEdit'

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'MODULE_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getModule({ id: this.$route.params.id })
            .then((res) => {
              this.module = res.data.data

              this.editRolesIds = this.module.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.module.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.module.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (!this.module.descriptions) this.module.descriptions = []

              if (this.module.loadCredentialId) {
                getCredential({ id: this.module.loadCredentialId })
                  .then((res) => {
                    this.credentials = [res.data.data]
                  })
                  .catch((err) => {
                    this.addSnackbar({
                      message: err,
                      timeout: 5000,
                      color: 'error'
                    })                  })
              }

              this.loading = false
              this.initialLoading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })
              this.loading = false
              this.initialLoading = false
            })
        } else if (this.$route.params.restore) {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.module = this.$route.params.restore
          this.module.id = 0
          this.loading = false
          this.initialLoading = false

        } else {
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.loading = false
          this.initialLoading = false
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    resourceTypeLength(type) {
      return this.module?.resources.filter((x) => x.type === type)?.length || 0
    },
    deleteModuleFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteModule({ id: this.module.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    async fetchSwagger() {
      if (!this.module.id) return

      this.loading = true
      this.lock = true

      let res = null

      const { id } = this.module

      try {

        res = await getSwagger({ id })

        if (res && res.status !== 200) {
          if (res.response && res.response.data && res.response.data.statusText) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.addSnackbar({
              message: this.$lang.errors.errorSwagger,
              timeout: 5000,
              color: 'error'
            })
          }
          this.loading = false
          this.lock = false

          return
        }

        this.download(JSON.stringify(res.data), `${this.module.name}-swagger-${this.$options.filters.formatDate(new Date())}.json`)

        this.loading = false
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    },
    async handleTransferModule() {
      if (!this.module.id) return

      this.loading = true
      this.lock = true

      this.tab = 1
      this.validationShow = false
      this.validationResult = null

      let res = null

      const { id, loadDirection } = this.module

      try {

        res = loadDirection === 'UPLOAD' ? await uploadModule({ moduleId: id }) : await downloadModule({ moduleId: id })

        if (res && res.status !== 200) {
          if (res.response && res.response.data && res.response.data.statusText) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            const errorMessage = loadDirection === 'UPLOAD' ? this.$lang.errors.moduleUpload : this.$lang.errors.moduleDownload

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.loading = false
          this.lock = false

          return
        }
        const successMessage = loadDirection === 'UPLOAD' ? this.$lang.success.moduleUploaded : this.$lang.success.moduleDownloaded

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.loading = false
        this.lock = false

        if (loadDirection === 'DOWNLOAD' && res.data) {
          this.validationResult = res.data.data
          this.validationShow = true
          this.tab = 4
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    },
    searchCredentialsFunction(val = '') {
      this.isLoadingCredentials = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      obj.type = 'FLOWY'

      getCredentials(obj)
        .then((res) => {
          this.credentials = res.data.data.items
          this.isLoadingCredentials = false
        })
        .catch((err) => {
          this.isLoadingCredentials = false
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    availableLanguages(item) {
      const selectedLangs = this.module.descriptions.map((x) => x.language)

      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      }).filter((x) => !selectedLangs.includes(x.value) || item.language === x.value)
    },
    confirmDeletion(item, index) {
      this.tempIndex = index
      this.tempItem = item
      this.yesNoShow = true
    },
    async deleteDesc() {
      this.module.descriptions.splice(this.tempIndex, 1)
      this.tempIndex = null
      this.tempItem = null
      this.yesNoShow = false
    },
    downloadModule(content, fileName, contentType) {
      const a = document.createElement('a')
      const file = new Blob([content], { type: contentType })

      a.href = URL.createObjectURL(file)
      a.download = fileName
      a.click()
      URL.revokeObjectURL(a.href)
      this.addSnackbar({
        message: this.$lang.success.moduleExport,
        timeout: 5000,
        color: 'success'
      })
    },
    download(content, fileName) {
      const file = new Blob([content],
        {
          type: 'application/json;charset=UTF-8'
        })

      const reader = new FileReader()

      reader.onload = function()
      {
        const popup = window.open()

        const link = document.createElement('a')

        link.setAttribute('href', reader.result)
        link.setAttribute('download', fileName)
        popup.document.body.appendChild(link)
        link.click()
      }
      reader.readAsDataURL(file)
    },
    async exportModuleFunction() {

      this.loading = true
      this.lock = true

      let res = null

      const { id, name } = this.module

      try {

        res = await exportModule({ id })

        if (res && res.status !== 200) {

          const errorMessage = this.isEdit ? this.$lang.errors.moduleUpdate : this.$lang.errors.moduleCreate

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }

        this.loading = false
        this.lock = false

        this.download(JSON.stringify(res.data.data), `${name}-${this.$options.filters.formatDate(new Date())}.json`, 'application/json')

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    },
    setResources(type, data) {
      const tempByType = this.module.resources.filter((x) => x.type !== type)

      const formattedData = data.map((x) => {
        return { type, id: x.id ? x.id : x, name: x.name }
      })

      this.module.resources = [...tempByType, ...formattedData]
    },
    setAdditionalResources(obj) {
      const { type, data } = obj
      const formattedData = { type, id: data.id ? data.id : data, name: data.name }

      const checkIdPresent = this.module.resources.find((x) => x.type === type && x.id === formattedData.id)

      if (checkIdPresent) return

      this.module.resources.push(formattedData)

      this.panelIndex = null
    },
    fetchHistory(options) {
      if (!this.module.id) return

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      obj.resourceId = this.module.id
      obj.resourceType = 'MODULE'

      getHistories(obj)
        .then((res) => {
          this.history = res.data.data
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    restoreModule(data) {
      this.module = data

      if (this.module && this.module.roles) {
        this.editRolesIds = this.module.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
        this.useRolesIds = this.module.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
        this.viewRolesIds = this.module.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

        this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    async submit() {
      this.err = ''
      this.success = ''
      this.info = ''

      if (!this.module.name.trim()) {
        this.addSnackbar({
          message: this.$lang.errors.moduleName,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false

        return
      }

      for (const item of this.module.descriptions) {
        if (!item.description.trim()) {
          this.addSnackbar({
            message: this.$lang.errors.moduleDescription,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }
      }

      if (this.editRolesIds.length < 1 && !this.isSuperUser) {
        this.addSnackbar({
          message: this.$lang.errors.editRoleCreate,
          timeout: 5000,
          color: 'error'
        })
      }

      this.loading = true
      this.lock = true

      const localCopyOfModule = JSON.parse(JSON.stringify(this.module))

      let res = null

      const { id } = localCopyOfModule

      if (!this.isEdit) {
        delete localCopyOfModule.id
      }
      delete localCopyOfModule.createdOn
      delete localCopyOfModule.modifiedOn

      if (!this.module.loadCredentialId) {
        delete localCopyOfModule.loadCredentialId
        delete localCopyOfModule.loadDirection
      }

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      localCopyOfModule.roles = tempRoles

      localCopyOfModule.resources.forEach((x) => {
        if (x.type === 'EXECUTE_PROCESS') {
          x.type = 'PROCESS'
        }
      })

      try {

        res = this.isEdit ? await updateModule({ id, body: localCopyOfModule }) : await createModule({ body: localCopyOfModule })

        if (res && res.status !== 200) {
          const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.moduleUpdate : this.$lang.errors.moduleCreate)

          this.addSnackbar({
            message: errorMessage,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.moduleUpdated : this.$lang.success.moduleCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })

        this.loading = false
        this.lock = false
        this.module = res.data.data
        this.isDirty = false
        this.isDirtyUpDown = false

        if (!this.isEdit) {
          this.isEdit = true
          this.$router.push({
            name: 'moduleEdit',
            params: {
              id: res.data.data.id
            }
          })
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'success'
        })
        this.loading = false
        this.lock = false
      }
    },
    getModuleIcon(type) {
      switch (type) {
      case 'GLOBAL_TRANSLATION':
        return 'mdi-text'
      case 'MODULE':
        return 'mdi-view-module-outline'
      case 'PLUGIN':
        return 'mdi-puzzle-outline'
      case 'LIBRARY':
        return 'mdi-library-outline'
      case 'PROCESS':
        return 'mdi-console-network'
      case 'PROCESS_CREDENTIAL':
        return 'mdi-lock'
      case 'SETTING':
        return 'mdi-cog'
      case 'TEXT_TEMPLATE':
        return 'mdi-text-box-outline'
      case 'TRIGGER_CRON':
        return 'mdi-briefcase-clock-outline'
      case 'TRIGGER_MESSAGING':
        return 'mdi-message-text-outline'
      case 'TRIGGER_REST':
        return 'mdi-web'
      case 'VALIDATION_RULE':
        return 'mdi-magnify-scan'
      case 'TRIGGER_EVENT_HANDLER':
        return 'mdi-briefcase-outline'
      case 'ENTITY':
        return 'mdi-database-settings-outline'
      default:
        return ''
      }
    }
  }
}
</script>

<style lang="scss">
  .module-icon {
    margin-right: 5px;
    flex-grow: unset !important;
  }
</style>
